import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { RelativeComponentLoader } from "@/Components/Loader";
import { Paginate } from "@/Components/Paginate";
import { DisplayTable } from "@/Components/Tables";
import { getExportLineage } from "@/helpers/apis";
import dayjs from "dayjs";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { NoResource } from "@/Components/NoResource";
export const ExportLineageList = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const { data, isLoading } = useQuery({
        queryKey: ["GET-EXPORT-LINEAGE", page],
        queryFn: () => getExportLineage({ page: page + 1, size: 10 }),
    });
    if (isLoading) {
        return _jsx(RelativeComponentLoader, { label: "Loading..." });
    }
    if (!(data === null || data === void 0 ? void 0 : data.total)) {
        return (_jsx(NoResource, { title: "No lineage exported", message: "Please visit the extension to export lineage." }));
    }
    return (_jsxs("div", { children: [_jsx("div", { className: "fs-3 mb-2", children: "Exported lineages" }), _jsx(DisplayTable, { header: [
                    { id: 1, label: "Name" },
                    { id: 2, label: "User" },
                    { id: 3, label: "Created at" },
                ], items: data === null || data === void 0 ? void 0 : data.items, templateColumns: "2fr 2fr 1fr", onItemClick: (i) => navigate(`/collaboration/export-lineage/${i.id}`), rowRender: (i) => (_jsxs(_Fragment, { children: [_jsx("div", { children: i.name }), _jsxs("div", { children: [i.user.first_name, " ", i.user.last_name] }), _jsx("div", { children: dayjs(i.created_at).format("DD MMM YYYY hh:mm") })] })), idKey: "id", resetPage: () => { } }), data && (_jsx(Paginate, { itemCount: data.total, page: page, pageSize: data.size, numPages: data.pages, onPageClick: setPage, onPageSizeChange: () => { } }))] }));
};
